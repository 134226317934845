define("ods-app/models/formacion-academica", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    candidato: belongsTo('candidato'),
    nivel: attr('string'),
    titulo: attr('string'),
    fechaInicio: attr('custom-date'),
    fechaFin: attr('custom-date'),
    establecimiento: attr('string'),
    fechaInicioFormatted: Ember.computed('fechaInicio', function () {
      let date = (0, _moment.default)(this.fechaInicio || '');
      return date.isValid() ? date.format('DD/MM/YYYY') : '';
    }),
    fechaFinFormatted: Ember.computed('fechaFin', function () {
      let date = (0, _moment.default)(this.fechaFin || '');
      return date.isValid() ? date.format('DD/MM/YYYY') : '';
    })
  });

  _exports.default = _default;
});
define("ods-app/templates/aplicacion/record/aspectos-evaluar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yoiFoJJv",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[0,\"\\n\"],[4,\"extended-form-for\",[[22,[\"changeset\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-xs-6\"],[8],[0,\"\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-textarea-field\"]],\"resumenEntrevista\",\"Resúmen de entrevista:\"],[[\"placeholder\"],[\"Resúmen de entrevista\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"col-xs-6\"],[8],[0,\"\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-textarea-field\"]],\"resumenTrayectoriaLaboral\",\"Resúmen de trayectoria laboral:\"],[[\"placeholder\"],[\"Resúmen de trayectoria laboral\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"pull-right\"],[8],[0,\"\\n\"],[4,\"link-to\",[\"aplicacion.record.aspectos-evaluar.record\",\"new\"],[[\"class\"],[\"btn btn-success btn-xs\"]],{\"statements\":[[0,\"    \"],[6,\"i\"],[10,\"class\",\"fa fa-plus-circle\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n    Agregar\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[6,\"br\"],[8],[9],[0,\"\\n\"],[6,\"br\"],[8],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[1,[26,\"tables/paginated-table\",null,[[\"columns\",\"modelName\",\"modelClassObject\",\"modelFilters\",\"includedRelationships\",\"load\"],[[22,[\"columns\"]],\"aplicacionAspectoEvaluar\",[22,[\"modelClass\"]],[22,[\"modelFilters\"]],[22,[\"includedRelationships\"]],[22,[\"reload\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/aplicacion/record/aspectos-evaluar.hbs"
    }
  });

  _exports.default = _default;
});
define("ods-app/routes/requerimiento/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs", "ods-app/mixins/ods-app-file-saver", "ods-app/mixins/permission-validator", "servir-ember-utilities/validators/custom-presence", "servir-ember-utilities/validators/is-codigo", "servir-ember-utilities/validators/presence-if-field-is-equal-to", "ember-changeset-validations/validators", "ods-app/validators/requerimiento-tipo-plaza-facturacion", "ods-app/validators/requerimiento-tipo-plaza-planilla", "ods-app/validators/requerimiento-motivo-contratacion", "moment", "ods-app/utils/common"], function (_exports, _abstractModuleRecord, _hasTabs, _odsAppFileSaver, _permissionValidator, _customPresence, _isCodigo, _presenceIfFieldIsEqualTo, _validators, _requerimientoTipoPlazaFacturacion, _requerimientoTipoPlazaPlanilla, _requerimientoMotivoContratacion, _moment, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, _odsAppFileSaver.default, _permissionValidator.default, {
    modelName: 'requerimiento',
    indexRoute: 'requerimiento',
    defaultTabSubRoute: 'informacion',
    routePermission: 'VIEW_REQUIREMENTS',
    user: Ember.computed.alias('currentUser.user'),
    proyectoPlaceHolderId: null,
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    datesCalculator: Ember.inject.service(),
    ajaxService: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    breadcrumbsRoute: Ember.computed('recordId', function () {
      let record = this.controller.get('record');
      let indexRoute = {
        name: 'Requerimientos',
        route: 'requerimiento'
      };

      if (['CONTRATADO', 'REPROCESO'].includes(record.get('estatus'))) {
        indexRoute = {
          name: 'Requerimiento histórico',
          route: 'requerimiento-historico'
        };
      }

      return [indexRoute, {
        name: 'Editar',
        route: `requerimiento.record.${this.defaultTabSubRoute}`,
        queryParam: this.recordId
      }];
    }),
    validations: Ember.computed(function () {
      let validaciones = {
        codigo: [(0, _customPresence.default)({
          presence: true
        }), (0, _isCodigo.default)()],
        proyecto: (0, _customPresence.default)({
          presence: true
        }),
        fechaSolicitud: (0, _customPresence.default)({
          presence: true
        }),
        estatus: (0, _customPresence.default)({
          presence: true
        }),
        reclutador: (0, _customPresence.default)({
          presence: true
        }),
        plaza: (0, _customPresence.default)({
          presence: true
        }),
        tipoPlaza: (0, _customPresence.default)({
          presence: true
        }),
        nombrePlaza: (0, _customPresence.default)({
          presence: true
        }),
        nombreJefeInmediato: (0, _customPresence.default)({
          presence: true
        }),
        cantidadPlazas: [(0, _customPresence.default)({
          presence: true
        }), (0, _validators.validateNumber)({
          gte: 0
        })],
        fechaSeguimientoProgramada: (0, _customPresence.default)({
          presence: true
        })
      };

      if (this.currentUser.isEmpresa('OPTIMUM')) {
        validaciones = Object.assign(validaciones, {
          pais: (0, _customPresence.default)({
            presence: true
          }),
          departamento: (0, _customPresence.default)({
            presence: true
          }),
          municipio: (0, _customPresence.default)({
            presence: true
          }),
          anosExperiencia: (0, _validators.validateNumber)({
            gte: 0
          }),
          personalBajoCargo: (0, _validators.validateNumber)({
            gte: 0
          }),
          edad: (0, _customPresence.default)({
            presence: true
          }),
          genero: (0, _customPresence.default)({
            presence: true
          }),
          bonificacionIncenAdmin: (0, _validators.validateNumber)({
            gte: 0
          }),
          bonificacionIncen: (0, _validators.validateNumber)({
            gte: 0
          }),
          bonoTransporte: (0, _validators.validateNumber)({
            gte: 0
          }),
          comisiones: (0, _validators.validateNumber)({
            gte: 0
          }),
          comisionesEspecificas: (0, _validators.validateNumber)({
            gte: 0
          }),
          jornada: (0, _customPresence.default)({
            presence: true
          }),
          gasolina: (0, _validators.validateNumber)({
            gte: 0
          })
        });
      } else if (this.currentUser.isEmpresa('EL_TAIER')) {
        validaciones = Object.assign(validaciones, {
          moneda: (0, _customPresence.default)({
            presence: true
          }),
          descripcion: (0, _customPresence.default)({
            presence: true
          }),
          diasCompletarRequerimiento: [(0, _customPresence.default)({
            presence: true
          }), (0, _validators.validateNumber)({
            gte: 1
          })]
        });
      } else if (this.currentUser.isEmpresa('SERVINOVA')) {
        validaciones = Object.assign(validaciones, {
          diasHorario: (0, _customPresence.default)({
            presence: true
          }),
          fechaInicioLabores: (0, _customPresence.default)({
            presence: true
          }),
          moneda: (0, _customPresence.default)({
            presence: true
          }),
          descripcion: (0, _customPresence.default)({
            presence: true
          }),
          diasCompletarRequerimiento: [(0, _customPresence.default)({
            presence: true
          }), (0, _validators.validateNumber)({
            gte: 1
          })],
          formacionAcademica: (0, _customPresence.default)({
            presence: true
          }),
          conocimientoRequerido: (0, _customPresence.default)({
            presence: true
          })
        });
      } else if (this.currentUser.isEmpresa('DEMO')) {
        validaciones = Object.assign(validaciones, {
          moneda: (0, _customPresence.default)({
            presence: true
          })
        });
      }

      return validaciones;
    }),
    relatedModelsToQuery: Ember.computed('isNew', 'proyectoPlaceHolderId', function () {
      let relatedModels = [{
        name: 'paises',
        modelName: 'pais'
      }, {
        name: 'departamentos',
        modelName: 'departamento'
      }, {
        name: 'municipios',
        modelName: 'municipio'
      }, {
        name: 'monedas',
        modelName: 'moneda'
      }, {
        name: 'usuarios',
        modelName: 'usuario',
        queryParams: {
          filter: {
            'role.puedeSerReclutador': 1,
            'enabled': 1,
            'isSuperAdmin': 0
          },
          include: 'role',
          sort: 'id'
        }
      }];

      if (!this.get('isNew') && this.get('recordId')) {
        relatedModels.push({
          name: 'ternas',
          modelName: 'terna',
          queryParams: {
            filter: {
              requerimiento: this.get('recordId')
            }
          }
        }, // Necesitamos lo cargos para hacer una validación al "Iniciar proceso".
        {
          name: 'planillaCargos',
          modelName: 'requerimiento-planilla-cargo',
          queryParams: {
            filter: {
              requerimiento: this.get('recordId')
            },
            include: 'planillaCargo'
          }
        });
      }

      if (this.get('proyectoPlaceHolderId')) {
        relatedModels.push({
          name: 'availableProjects',
          modelName: 'proyecto',
          queryParams: {
            filter: {
              id: this.get('proyectoPlaceHolderId')
            },
            include: 'servicioAdquirido,clienteContacto'
          }
        });
      }

      return relatedModels;
    }),

    model(params, transition) {
      let proyectoId = transition.queryParams.proyectoId; // Después del super, cargar la data necesaria para mostrar correctamente
      // la información de proyecto.

      return this._super(...arguments).then(modelData => {
        let record = modelData.record;
        let perfilesPuestoAsociados = this.user.get('perfilPuestos');
        let perfilesPuestoAsociadosIds = null;

        if (perfilesPuestoAsociados) {
          perfilesPuestoAsociadosIds = perfilesPuestoAsociados.map(p => p.id);
        }

        if (record.get('isNew')) {
          if (!proyectoId) {
            return modelData;
          }

          return Ember.RSVP.hash({
            proyecto: this.store.findRecord('proyecto', proyectoId)
          }).then(({
            proyecto
          }) => {
            record.set('proyecto', proyecto);
            return Ember.RSVP.hash({
              clientePerfilesPuesto: this.store.query('cliente-perfil-puesto', {
                filter: {
                  cliente: proyecto.get('cliente.id'),
                  id: perfilesPuestoAsociadosIds
                },
                include: 'rangoSalarial'
              })
            }).then(proyectoData => Ember.merge(modelData, proyectoData));
          });
        }

        let proyecto = record.get('proyecto');
        let promisesArray = [proyecto];
        return Ember.RSVP.all(promisesArray).then(() => {
          promisesArray = [proyecto.get('cliente'), proyecto.get('servicioAdquirido')];
          return Ember.RSVP.all(promisesArray);
        }).then(() => {
          let query = {
            proyectoCandidatos: this.store.query('proyecto-candidato', {
              filter: {
                'proyecto': proyecto.get('id')
              }
            })
          };

          if (this.currentUser.isEmpresa('EL_TAIER') || this.currentUser.isEmpresa('DEMO') || this.currentUser.isEmpresa('SERVINOVA')) {
            let queryPerfilesPuesto = {
              clientePerfilesPuesto: this.store.query('cliente-perfil-puesto', {
                filter: {
                  'cliente': proyecto.get('cliente.id'),
                  id: perfilesPuestoAsociadosIds
                },
                include: 'rangoSalarial'
              })
            };
            query = Object.assign(query, queryPerfilesPuesto);
          }

          return Ember.RSVP.hash(query);
        }).then(data => Ember.merge(modelData, data));
      });
    },

    createRecordInstance(params, transition) {
      let modelName = this.get('modelName'); // Por defecto this.fechaSolicitud viene en null, por lo que el moment construye
      // la fecha del día

      let fechaSolicitud = (0, _moment.default)(this.fechaSolicitud);

      if (this.currentUser.isEmpresa('OPTIMUM')) {
        // Si la hora a la que se está creando el requerimiento es mayor a las 10:00 a.m.
        // entonces registrar como fecha de solicitud el día siguiente
        if (parseInt(fechaSolicitud.format('HH')) > 10) {
          fechaSolicitud = this.datesCalculator.getFechaLuegoDeDiasHabiles(fechaSolicitud, 1);
        }
      } // La fecha de seguimiento programada se establece como el tercer día hábil
      // posterior a la fecha registrada de solicitud


      let fechaSeguimientoProgramada = this.datesCalculator.getFechaLuegoDeDiasHabiles(fechaSolicitud, 3);

      if (this.currentUser.isEmpresa('SERVINOVA')) {
        // La fecha de seguimiento programada se establece como el siguiente día hábil
        // posterior a la fecha registrada de solicitud
        fechaSeguimientoProgramada = this.datesCalculator.getFechaLuegoDeDiasHabiles(fechaSolicitud, 1);
      }

      if (!Ember.isNone(transition.queryParams.proyectoId)) {
        this.set('proyectoPlaceHolderId', transition.queryParams.proyectoId);
      }

      let defaultValues = {
        estatus: 'BORRADOR',
        fechaSeguimientoProgramada: fechaSeguimientoProgramada.toDate(),
        anosExperiencia: 0,
        personalBajoCargo: 0,
        cantidadPlazas: 1,
        fechaSolicitud: fechaSolicitud.toDate()
      };

      if (this.currentUser.isEmpresa('EL_TAIER') || this.currentUser.isEmpresa('DEMO') || this.currentUser.isEmpresa('SERVINOVA')) {
        defaultValues = Ember.merge(defaultValues, {
          responsableSolicitud: this.user.get('nombreCompleto'),
          responsablePuesto: this.user.get('puesto'),
          responsableCorreo: this.user.get('email'),
          responsableTelefono: this.user.get('celular') ? this.user.get('celular') : this.user.get('telefono'),
          permiteAplicaciones: 1
        });
      }

      return this.store.createRecord(modelName, defaultValues);
    },

    setupController(controller, model) {
      this._super(controller, model);

      let changeset = controller.changeset;

      if (this.user.get('role.codigo') != 'OP01') {
        this.validations['salarioBase'] = (0, _requerimientoTipoPlazaPlanilla.default)({
          changeset
        });
        this.validations['bonificacionDecreto'] = (0, _requerimientoTipoPlazaPlanilla.default)({
          changeset
        });
        this.validations['montoHonorario'] = (0, _requerimientoTipoPlazaFacturacion.default)({
          changeset
        });
      }

      if (this.currentUser.isEmpresa('SERVINOVA')) {
        // Campo requerido solo si el cliente NO es externo.
        this.validations['cantidadTotalExperiencia'] = (0, _presenceIfFieldIsEqualTo.default)({
          changeset,
          dependsOn: 'proyecto.cliente.esExterno',
          comparisonValue: true,
          inverse: true,
          presence: true
        }); // Campo requerido solo si el motivo de contratación es "CRECIMIENTO" o "NUEVA"

        this.validations['equipoRequerido'] = (0, _requerimientoMotivoContratacion.default)({
          changeset
        });
      }

      if (model.record.get('isNew')) {
        let reclutador = controller.get('usuarios').findBy('role.codigo', this.constants.ROLE_GERENCIA_RH_CODE);

        if (this.currentUser.isEmpresa('EL_TAIER') || this.currentUser.isEmpresa('DEMO') || this.currentUser.isEmpresa('SERVINOVA')) {
          reclutador = controller.get('usuarios').findBy('role.codigo', this.constants.ROLE_RH_CODE_EL_TAIER);
        }

        changeset.set('reclutador', reclutador);
        changeset.set('responsable', reclutador);
      } else {
        if (this.currentUser.isEmpresa('EL_TAIER') || this.currentUser.isEmpresa('DEMO')) {
          let tiposPlaza = [];
          let plaza = changeset.get('plaza');

          if (plaza == 'NUEVA' || plaza == 'REEMPLAZO') {
            tiposPlaza.push({
              key: 'PLANILLA',
              name: 'Planilla'
            }, {
              key: 'FACTURACION_INTERNA_12_MESES',
              name: 'Facturación interna 12 meses'
            }, {
              key: 'FACTURACION_INTERNA_14_MESES',
              name: 'Facturación interna 14 meses'
            });
          } else if (plaza == 'FREELANCE') {
            tiposPlaza.push({
              key: 'FACTURACION_EXTERNA',
              name: 'Factura externa'
            });
          } else if (plaza == 'TEMPORAL') {
            tiposPlaza.push({
              key: 'FACTURACION_INTERNA_12_MESES',
              name: 'Facturación interna 12 meses'
            });
          }

          Ember.set(model, 'tiposPlaza', tiposPlaza);
        } else if (this.currentUser.isEmpresa('SERVINOVA')) {
          let cliente = changeset.get('proyecto').get('cliente');

          if (cliente && cliente.get('esExterno')) {
            Ember.set(model, 'tiposPlaza', this.constants.HIRING_TYPES_SERVINOVA_EXTERNO);
          } else {
            Ember.set(model, 'tiposPlaza', this.constants.HIRING_TYPES_SERVINOVA_INTERNO);
          }
        }
      }

      if (!Ember.isNone(controller.get('availableProjects'))) {
        let proyecto = controller.get('availableProjects.firstObject');

        if (this.currentUser.isEmpresa('OPTIMUM')) {
          let clienteContacto = proyecto.get('clienteContacto');
          changeset.set('responsableSolicitud', clienteContacto.get('nombre'));
          changeset.set('responsablePuesto', clienteContacto.get('puesto'));
          changeset.set('responsableCorreo', clienteContacto.get('correo'));
          changeset.set('responsableTelefono', clienteContacto.get('telefono'));

          if (proyecto.get('servicioAdquirido.tipoServicio') === 'ADMINISTRACION_PLANILLA_FACTORH') {
            changeset.set('tipoPlaza', 'PLANILLA');
          }
        }
      }
    },

    actions: {
      iniciarProcesoDeReclutamiento() {
        let changeset = this.controller.changeset;
        let tipoServicio = changeset.get('proyecto').get('servicioAdquirido.tipoServicio'); // Validar que ya se haya asginado un puesto de FactoRH.

        if (tipoServicio === 'ADMINISTRACION_PLANILLA_FACTORH' && changeset.get('tipoPlaza') === 'PLANILLA') {
          if (!changeset.get('factorhPuesto')) {
            this.toast.error('Debe asignar un puesto de FactoRH al requerimiento.');
            return;
          }

          let reqCargosSinTipoDescuento = changeset.get('planillaCargos').filterBy('generaDescuentoEmpleado').rejectBy('planillaCargo.factorhTipoDescuentoId').mapBy('planillaCargo.nombre');

          if (reqCargosSinTipoDescuento.length) {
            this.toast.error(`Los siguientes cargos que generan descuento de empleado
            no tienen configurado su tipo de descuento de FactoRH: ${reqCargosSinTipoDescuento.join(', ')}
            `);
            return;
          }
        }

        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro de iniciar el proceso de reclutamiento?`,
          message: ['Ya no podrá realizar ningún cambio sobre los datos generales, el perfil', 'laboral y la información de compensación.'].join(' '),
          onSubmit: () => {
            // Por defecto this.fechaSolicitud viene en null, por lo que el moment construye
            // la fecha del día
            let fechaSolicitud = (0, _moment.default)(); // Si la hora a la que se está creando el requerimiento es mayor a las 11:00 a.m.
            // entonces registrar como fecha de solicitud el día siguiente

            if (parseInt(fechaSolicitud.format('HH')) > 10) {
              fechaSolicitud = this.datesCalculator.getFechaLuegoDeDiasHabiles(fechaSolicitud, 1);
            } // La fecha de seguimiento programada se establece como el tercer día hábil
            // posterior a la fecha registrada de solicitud


            let fechaSeguimientoProgramada = this.datesCalculator.getFechaLuegoDeDiasHabiles(fechaSolicitud, 3);

            if (this.currentUser.isEmpresa('SERVINOVA')) {
              // La fecha de seguimiento programada se establece como el siguiente día hábil
              // posterior a la fecha registrada de solicitud
              fechaSeguimientoProgramada = this.datesCalculator.getFechaLuegoDeDiasHabiles(fechaSolicitud, 1);
            }

            changeset.set('estatus', 'VACANTE');
            changeset.set('fechaSolicitud', fechaSolicitud.toDate());
            changeset.set('fechaSeguimientoProgramada', fechaSeguimientoProgramada.toDate());
            changeset.set('esEditable', false);
            return this.controller.submit().then(() => {
              this.toast.success('Proceso de reclutamiento iniciado correctamente.');
            });
          }
        });
      },

      iniciarProcesoDeReclutamientoElTaier() {
        if (!this.get('currentUser').hasPermission('AUTHORIZE_REQUIREMENT')) {
          this.toast.error('No tiene el permiso necesario para realizar esta acción.');
          return;
        }

        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro de iniciar el proceso de reclutamiento?`,
          message: ['Ya no podrá realizar ningún cambio sobre los datos generales, el perfil', 'laboral y la información de compensación.'].join(' '),
          onSubmit: () => {
            let changeset = this.controller.changeset;
            let fechaSolicitud = changeset.get('fechaSolicitud') ? (0, _moment.default)(changeset.get('fechaSolicitud')) : (0, _moment.default)(); // Si la hora a la que se está creando el requerimiento es mayor a las 11:00 a.m.
            // entonces registrar como fecha de solicitud el día siguiente

            if (!changeset.get('fechaSolicitud') && parseInt(fechaSolicitud.format('HH')) > 10) {
              fechaSolicitud = this.datesCalculator.getFechaLuegoDeDiasHabiles(fechaSolicitud, 1);
            } // La fecha de seguimiento programada se establece como el tercer día hábil
            // posterior a la fecha registrada de solicitud


            let fechaSeguimientoProgramada = this.datesCalculator.getFechaLuegoDeDiasHabiles(fechaSolicitud, 3);

            if (this.currentUser.isEmpresa('SERVINOVA')) {
              // La fecha de seguimiento programada se establece como el siguiente día hábil
              // posterior a la fecha registrada de solicitud
              fechaSeguimientoProgramada = this.datesCalculator.getFechaLuegoDeDiasHabiles(fechaSolicitud, 1);
            }

            changeset.set('estatus', 'VACANTE');
            changeset.set('fechaSolicitud', fechaSolicitud.toDate());
            changeset.set('estaIniciadoProcesoReclutamiento', true);
            changeset.set('fechaSeguimientoProgramada', fechaSeguimientoProgramada.toDate());
            return this.controller.submit().then(() => {
              this.toast.success('Proceso de reclutamiento iniciado correctamente.');
            });
          }
        });
      },

      crearReproceso() {
        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro que quiere generar un reproceso?`,
          message: `Esto es un proceso irreversible`,
          onSubmit: () => {
            let changeset = this.controller.get('changeset');
            changeset.set('estatus', 'REPROCESO');
            changeset.set('estaDeshabilitado', true); // El return no hace nada en este momento, pero lo ponemos por si en el
            // futuro se mejora el popup de confirmación para escuchar la promesa.

            return this.controller.submit().then(() => {
              this.toast.success('Requerimiento actual cerrado correctamente.');
              let requerimiento = this.modelFor('requerimiento.record').record;
              let fechaSolicitud = (0, _moment.default)(); // Si la hora a la que se está creando el requerimiento es mayor a las 11:00 a.m.
              // entonces registrar como fecha de solicitud el día siguiente

              if (parseInt(fechaSolicitud.format('HH')) > 10) {
                fechaSolicitud = this.datesCalculator.getFechaLuegoDeDiasHabiles(fechaSolicitud, 1);
              } // // Fecha seguimiento


              let fechaSeguimientoProgramada = this.datesCalculator.getFechaLuegoDeDiasHabiles(fechaSolicitud, 3);

              if (this.currentUser.isEmpresa('SERVINOVA')) {
                // La fecha de seguimiento programada se establece como el siguiente día hábil
                // posterior a la fecha registrada de solicitud
                fechaSeguimientoProgramada = this.datesCalculator.getFechaLuegoDeDiasHabiles(fechaSolicitud, 1);
              }

              let requerimientoReproceso = this.store.createRecord('requerimiento', {
                esReprocesoDe: requerimiento,
                codigo: changeset.get('codigo').concat('R'),
                proyecto: changeset.get('proyecto'),
                fechaSolicitud: fechaSolicitud.toDate(),
                estatus: 'BORRADOR',
                reclutador: changeset.get('reclutador'),
                plaza: changeset.get('plaza'),
                cantidadPlazas: changeset.get('cantidadPlazas'),
                tipoPlaza: changeset.get('tipoPlaza'),
                factorhPuestoStr: changeset.get('factorhPuestoStr'),
                nombrePlaza: changeset.get('nombrePlaza'),
                nombreJefeInmediato: changeset.get('nombreJefeInmediato'),
                supervisorOperativo: changeset.get('supervisorOperativo'),
                pais: changeset.get('pais'),
                departamento: changeset.get('departamento'),
                municipio: changeset.get('municipio'),
                ubicacion: changeset.get('ubicacion'),
                jornada: changeset.get('jornada'),
                diasHorario: changeset.get('diasHorario'),
                sustituye: changeset.get('sustituye'),
                fechaAltaProyecto: changeset.get('fechaAltaProyecto'),
                fechaSeguimientoProgramada: fechaSeguimientoProgramada.toDate(),
                esUrgente: changeset.get('esUrgente'),
                responsableSolicitud: changeset.get('responsableSolicitud'),
                responsablePuesto: changeset.get('responsablePuesto'),
                responsableCorreo: changeset.get('responsableCorreo'),
                responsableTelefono: changeset.get('responsableTelefono'),
                observacionesRecursos: changeset.get('observacionesRecursos'),
                observacionesComercial: changeset.get('observacionesComercial'),
                montoHonorario: changeset.get('montoHonorario') || 0,
                salarioBase: changeset.get('salarioBase') || 0,
                bonificacionDecreto: changeset.get('bonificacionDecreto') || 0,
                bonificacionIncenAdmin: changeset.get('bonificacionIncenAdmin') || 0,
                bonificacionIncen: changeset.get('bonificacionIncen') || 0,
                bonoTransporte: changeset.get('bonoTransporte') || 0,
                comisiones: changeset.get('comisiones') || 0,
                comisionesEspecificas: changeset.get('comisionesEspecificas') || 0,
                gasolina: changeset.get('gasolina') || 0,
                escolaridad: changeset.get('escolaridad'),
                idiomas: changeset.get('idiomas'),
                programasComputacion: changeset.get('programasComputacion'),
                anosExperiencia: changeset.get('anosExperiencia'),
                areasExperiencia: changeset.get('areasExperiencia'),
                edad: changeset.get('edad'),
                genero: changeset.get('genero'),
                estadoCivil: changeset.get('estadoCivil'),
                religion: changeset.get('religion'),
                vehiculo: changeset.get('vehiculo'),
                tipoLicencia: changeset.get('tipoLicencia'),
                infoVehiculo: changeset.get('infoVehiculo'),
                funcionesBasicas: changeset.get('funcionesBasicas'),
                competencias: changeset.get('competencias'),
                responsabilidades: changeset.get('responsabilidades'),
                personalBajoCargo: changeset.get('personalBajoCargo'),
                presentacion: changeset.get('presentacion'),
                responsable: changeset.get('responsable')
              });
              return requerimientoReproceso.save().then(() => {
                this.toast.success('Requerimiento de reproceso creado correctamente.');
                this.transitionTo('requerimiento.record', requerimientoReproceso.id);
              }).catch(error => (0, _common.handleChangesetSaveErrors)(requerimientoReproceso, error, this.toast));
            });
          }
        });
      },

      marcarRequerimientoComoConfirmadoPendiente() {
        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro de marcar como confirmado pendiente de ingreso el requerimiento?`,
          message: null,
          onSubmit: () => {
            let changeset = this.controller.get('changeset');
            let ternaActiva = changeset.get('ternaActiva');

            if (!ternaActiva) {
              this.toast.error('El requerimiento no cuenta con terna activa.');
              return;
            }

            if (!ternaActiva.get('aceptada')) {
              this.toast.error('La terna no ha sido aceptada.');
              return;
            }

            changeset.set('fechaFinalizacion', new Date());
            changeset.set('estatus', 'CONFIRMADO_PENDIENTE_DE_INGRESO');
            return this.controller.submit().then(() => {
              this.toast.success('Requerimiento marcado como confirmado pendiente correctamente.');
            });
          }
        });
      },

      marcarRequerimientoComoCongelado() {
        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro de marcar como congelado el requerimiento?`,
          message: null,
          onSubmit: () => {
            let changeset = this.controller.get('changeset');
            changeset.set('estatus', 'CONGELADO');
            changeset.set('estaDeshabilitado', true);
            return this.controller.submit().then(() => {
              this.toast.success('Requerimiento marcado como congelado correctamente.');
            });
          }
        });
      },

      marcarRequerimientoComoContratado() {
        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro de marcar como contratado el requerimiento?`,
          message: null,
          onSubmit: () => {
            let changeset = this.controller.get('changeset');
            let tipoServicio = changeset.get('proyecto.servicioAdquirido.tipoServicio');
            let aplicaciones = changeset.get('ternaActiva.aplicaciones');
            let aplicacionesContratadas = 0;
            let isValid = true;

            if (this.currentUser.isEmpresa('SERVINOVA')) {
              let fechaSeguimiento = changeset.get('fechaSeguimiento');

              if (!fechaSeguimiento) {
                this.toast.error('Debe ingresar la fecha de seguimiento.');
                return;
              }
            }

            aplicaciones.map(aplicacion => {
              if (aplicacion.get('seleccionado') && (aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_CONTRATADO || aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_COMPLETADO)) {
                aplicacionesContratadas += 1;
              }
            }); // Se comenta condición ya que este permiso no debería de obviar la condición de contratar la misma cantidad
            // de aplicaciones que pide el requerimiento para contratar el requerimiento
            // if (!(this.get('currentUser').hasPermission('APPROVE_SPECIAL_TERNA') && changeset.get('esUrgente'))) {
            // Para el tipo de servicio Perfilamiento unicamente es necesario llegar hasta aplicaciones por lo que no
            // existe la figura de terna.

            if (tipoServicio !== this.constants.SERVICE_TYPE_PERFILAMIENTO && aplicacionesContratadas !== parseInt(changeset.get('cantidadPlazas')) && !changeset.get('marcarComoContratadoFactorh')) {
              isValid = false;
            } // Si el usuario intenta marcar como contratado FactorH sin el permiso necesario, no dejamos marcarlo como
            // contratado


            if (changeset.get('marcarComoContratadoFactorh') && !this.get('currentUser').hasPermission('MARK_RQ_CONTRATADO_FH')) {
              isValid = false;
            }

            if (!isValid) {
              this.toast.error('No han sido contratadas las aplicaciones necesarias.');
              return;
            } // Si el usuario intenta marcar como contratado FactorH, se marcan las aplicaciones seleccionadas
            // como contratadas y se asigna la fecha de ingreso si es que no tienen


            if (changeset.get('marcarComoContratadoFactorh')) {
              aplicaciones.map(aplicacion => {
                if (aplicacion.get('seleccionado')) {
                  aplicacion.set('estatus', this.constants.APPLICATION_STATUS_CONTRATADO);

                  if (!aplicacion.get('fechaIngreso')) {
                    aplicacion.set('fechaIngreso', new Date());
                  }

                  aplicacion.save();
                }
              });
            }

            changeset.set('estatus', 'CONTRATADO');
            changeset.set('estaDeshabilitado', true);
            changeset.set('fechaFinalizacion', new Date());
            return this.controller.submit().then(() => {
              this.toast.success('Requerimiento marcado como contratado correctamente.');
            });
          }
        });
      },

      descongelarRequerimiento() {
        let changeset = this.controller.get('changeset');
        changeset.set('estatus', 'VACANTE');
        changeset.set('estaDeshabilitado', false);
        return this.controller.submit().then(() => {
          this.toast.success('Requerimiento descongelado correctamente.');
        });
      },

      reprogramarEntrevista() {
        let req = this.modelFor('requerimiento.record').record;
        let terna = req.get('ternaActiva');
        terna.set('reprogramar', true);
      },

      downloadPdf() {
        let requerimiento = this.controller.get('record');
        return this.getFileFromServer(`/requerimientos/${requerimiento.get('id')}/descarga_pdf`);
      },

      duplicarRequerimiento() {
        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro que desea duplicar el requerimiento?`,
          message: 'Esto lo rediccionará al nuevo requerimiento y se perderán los cambios no guardados del actual.',
          onSubmit: () => {
            let requerimiento = this.controller.get('record');
            this.loader.setIsLoading();
            this.ajaxService.request(`/requerimiento/${requerimiento.get('id')}/duplicar`).then(data => {
              let dataReturned = data.data;
              this.toast.success(dataReturned.message);
              this.transitionTo('requerimiento.record', dataReturned.new_requerimiento_id);
            }).catch(error => {
              this.toast.error('Ocurrió un error al intentar duplicar el requerimiento.');
              throw error;
            }).finally(() => this.loader.setNotLoading());
          }
        });
      },

      enviarNotificacionFaseTerna() {
        let requerimiento = this.controller.get('record');
        this.loader.setIsLoading();
        this.ajaxService.request(`/requerimiento/${requerimiento.get('id')}/notificacion-fase-terna`).then(data => {
          this.toast.success(data.message);
        }).catch(error => {
          this.toast.error('Ocurrió un error al enviar la notificación.');
          throw error;
        }).finally(() => this.loader.setNotLoading());
      },

      reasignarProyecto() {
        let requerimiento = this.controller.get('record');

        if (requerimiento.get('estatus') == 'VACANTE' || requerimiento.get('estatus') == 'CONFIRMADO_PENDIENTE_DE_INGRESO') {
          this.transitionTo('requerimiento.record.informacion.reasignar-proyecto');
        } else {
          this.toast.error('No es posible realizar esta acción en este momento..');
          return;
        }
      },

      mandarAValidar() {
        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro que desea mandar el requerimiento a revisión?`,
          message: '',
          onSubmit: () => {
            let changeset = this.controller.changeset;
            changeset.set('estatus', 'EN_REVISION');
            changeset.set('esEditable', false);
            return this.controller.submit().then(() => {
              this.toast.success('Se ha cambiado el estatus del requerimiento correctamente.');
            });
          }
        });
      },

      solicitarAutorizacion() {
        if (!this.get('currentUser').hasPermission('VALIDATE_REQUIREMENT')) {
          this.toast.error('No tiene el permiso necesario para realizar esta acción.');
          return;
        }

        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro que desea mandar a autorizar el requerimiento?`,
          message: '',
          onSubmit: () => {
            let changeset = this.controller.changeset;
            changeset.set('estatus', 'PENDIENTE_DE_AUTORIZAR');
            return this.controller.submit().then(() => {
              this.toast.success('Se ha cambiado el estatus del requerimiento correctamente.');
            });
          }
        });
      }

    }
  });

  _exports.default = _default;
});
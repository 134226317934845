define("ods-app/routes/aplicacion/record/aspectos-evaluar/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    routeRecordIdentifierDynamicSegment: 'aspecto_id',
    modelName: 'aplicacion-aspecto-evaluar',
    indexRoute: 'aplicacion.record.aspectos-evaluar',
    validations: Ember.computed(function () {
      return {
        seccionInforme: (0, _validators.validatePresence)({
          presence: true
        }),
        observaciones: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),
    relatedModelsToQuery: Ember.computed(function () {
      return [{
        name: 'secciones',
        modelName: 'seccion-informe',
        queryParams: {
          filter: {
            activo: 1
          }
        }
      }];
    }),

    model() {
      return this._super(...arguments).then(modelData => {
        return Ember.RSVP.all([modelData.record.get('upload')]).then(() => modelData);
      });
    },

    createRecordInstance() {
      const aplicacion = this.modelFor('aplicacion.record').record;
      const modelName = this.get('modelName');
      return this.store.createRecord(modelName, {
        aplicacion
      });
    },

    setupController(controller, model) {
      this._super(controller, model); // WARNING: This line creates the changeset for the file.


      controller._setNuevoArchivo();
    },

    actions: {
      willTransition() {
        return true;
      }

    }
  });

  _exports.default = _default;
});
define("ods-app/controllers/seccion-informe", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/seccion-informe"], function (_exports, _abstractModuleIndex, _seccionInforme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelName: 'seccion-informe',
    modelClass: _seccionInforme.default,
    editRoute: 'seccion-informe.record',
    reload: true,
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        sortable: false,
        width: '50px',
        filtrable: true,
        filterName: 'codigo'
      }, {
        label: 'Nombre',
        valuePath: 'nombre',
        sortable: false,
        width: '150px',
        filtrable: true,
        filterName: 'nombre'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '50px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    }),

    reloadModel() {
      this.set('reload', true);
    }

  });

  _exports.default = _default;
});
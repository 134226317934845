define("ods-app/routes/candidato/record/experiencia-laboral/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    modelName: 'experiencia-laboral',
    indexRoute: 'candidato.record.experiencia-laboral',
    routeRecordIdentifierDynamicSegment: 'experiencialaboral_id',
    validations: Ember.computed(function () {
      return {
        nombreEmpresa: (0, _validators.validatePresence)({
          presence: true
        }),
        puesto: (0, _validators.validatePresence)({
          presence: true
        }),
        fechaIngreso: (0, _validators.validatePresence)({
          presence: true
        }) // fechaEgreso: validatePresence({ presence: true }),
        // motivoRetiro: validatePresence({ presence: true }),

      };
    }),

    createRecordInstance() {
      const candidato = this.modelFor('candidato.record').record;
      const modelName = this.get('modelName');
      return this.store.createRecord(modelName, {
        candidato
      });
    },

    actions: {
      willTransition() {
        return true;
      }

    }
  });

  _exports.default = _default;
});
define("ods-app/routes/candidato/record/formacion-academica/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    modelName: 'formacion-academica',
    indexRoute: 'candidato.record.formacion-academica',
    routeRecordIdentifierDynamicSegment: 'formacion_academica_id',
    validations: Ember.computed(function () {
      return {
        nivel: (0, _validators.validatePresence)({
          presence: true
        }),
        titulo: (0, _validators.validatePresence)({
          presence: true
        }),
        establecimiento: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    createRecordInstance() {
      const candidato = this.modelFor('candidato.record').record;
      const modelName = this.get('modelName');
      return this.store.createRecord(modelName, {
        candidato
      });
    },

    actions: {
      willTransition() {
        return true;
      }

    }
  });

  _exports.default = _default;
});
define("ods-app/controllers/aplicacion/record/aspectos-evaluar/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record", "ods-app/mixins/upload-helper"], function (_exports, _abstractModuleRecord, _uploadHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_uploadHelper.default, {
    indexCtrl: Ember.inject.controller('aplicacion.record.aspectos-evaluar'),
    indexRoute: 'aplicacion.record.aspectos-evaluar',
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),

    postSave() {
      this.indexCtrl.reloadModel();
    },

    processSave(changeset) {
      let superRef = this._super.bind(this);

      if (changeset.get('upload.id')) {
        return superRef(...arguments);
      }

      return this.uploadCurrentFile().then(uploadRecord => {
        changeset.set('upload', uploadRecord);
        return superRef(...arguments);
      });
    },

    actions: {
      discardChanges() {
        if (this.changeset.get('isDirty')) {
          this.record.rollbackAttributes();
        }

        this.transitionToRoute(this.get('indexRoute'));
      }

    }
  });

  _exports.default = _default;
});
define("ods-app/models/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    SERVICE_TYPES: [{
      key: 'CON_RECLUTAMIENTO_SELECCION',
      name: 'Con reclutamiento y selección'
    }, {
      key: 'CON_RECLUTAMIENTO_SELECCION_FACTORH',
      name: 'Con reclutamiento y selección para FactoRH'
    }, {
      key: 'SIN_RECLUTAMIENTO_SELECCION',
      name: 'Sin reclutamiento y selección'
    }, {
      key: 'ADMINISTRACION_PLANILLA_FACTORH',
      name: 'Administración de planilla FactoRH'
    }, {
      key: 'PERFILAMIENTO',
      name: 'Perfilamiento'
    }],
    SERVICE_TYPE_PERFILAMIENTO: 'PERFILAMIENTO',
    ROLE_GERENCIA_RH_CODE: 'GerenciaRH',
    ROLE_RH_CODE_EL_TAIER: 'RH01',
    REQUIREMENT_SOURCE_TYPES: [{
      key: '',
      name: 'Seleccionar'
    }, {
      key: 'CLIENTES',
      name: 'Clientes'
    }, {
      key: 'CANDIDATOS',
      name: 'Candidatos'
    }],
    REQUIREMENT_SOURCE_TYPE_CLIENTE: 'CLIENTES',
    REQUIREMENT_SOURCE_TYPE_CANDIDATO: 'CANDIDATOS',
    CANCELLATION_REASON_SOURCE_TYPES: [{
      key: '',
      name: 'Seleccionar'
    }, {
      key: 'PROYECTOS',
      name: 'Proyectos'
    }, {
      key: 'REQUERIMIENTOS',
      name: 'Requerimientos'
    }, {
      key: 'APLICACION',
      name: 'Aplicación'
    }],
    CANCELLATION_REASON_SOURCE_TYPE_PROYECTO: 'PROYECTOS',
    CANCELLATION_REASON_SOURCE_TYPE_REQUERIMIENTO: 'REQUERIMIENTOS',
    REQUIREMENT_SITUATION_EN_TIEMPO: 'EN_TIEMPO',
    REQUIREMENT_SITUATION_FUERA_DE_TIEMPO: 'FUERA_DE_TIEMPO',
    PROJECT_STATUS: [{
      key: 'ACTIVO',
      name: 'Activo'
    }, {
      key: 'FACTURADO',
      name: 'Facturado'
    }, {
      key: 'COMPLETADO',
      name: 'Completado'
    }, {
      key: 'ANULADO',
      name: 'Anulado'
    }, {
      key: 'EN_FACTURACION',
      name: 'En facturación'
    }],
    PROJECT_STATUS_ACTIVE: 'ACTIVO',
    PROJECT_STATUS_INVOICED: 'FACTURADO',
    PROJECT_STATUS_COMPLETED: 'COMPLETADO',
    PROJECT_STATUS_BILLING: 'EN_FACTURACION',
    PROJECT_STATUS_CANCELED: 'ANULADO',
    REQUIREMENT_STATUS: [{
      key: 'BORRADOR',
      name: 'Borrador'
    }, {
      key: 'VACANTE',
      name: 'Vacante'
    }, {
      key: 'CONFIRMADO_PENDIENTE_DE_INGRESO',
      name: 'Confirmado pendiente de ingreso'
    }, {
      key: 'CONTRATADO',
      name: 'Contratado'
    }, {
      key: 'CONGELADO',
      name: 'Congelado'
    }, {
      key: 'CANCELADO',
      name: 'Cancelado'
    }, {
      key: 'REPROCESO',
      name: 'Reproceso'
    }],
    REQUIREMENT_STATUS_EL_TAIER: [{
      key: 'BORRADOR',
      name: 'Borrador'
    }, {
      key: 'EN_REVISION',
      name: 'En revisión'
    }, {
      key: 'PENDIENTE_DE_AUTORIZAR',
      name: 'Pendiente de autorizar'
    }, {
      key: 'VACANTE',
      name: 'Vacante'
    }, {
      key: 'CONFIRMADO_PENDIENTE_DE_INGRESO',
      name: 'Confirmado pendiente de ingreso'
    }, {
      key: 'CONTRATADO',
      name: 'Contratado'
    }, {
      key: 'CONGELADO',
      name: 'Congelado'
    }, {
      key: 'CANCELADO',
      name: 'Cancelado'
    }],
    JOB_TYPES: [{
      key: 'NUEVA',
      name: 'Nueva'
    }, {
      key: 'EXISTENTE',
      name: 'Existente'
    }, {
      key: 'SUSTITUCION_TEMPORAL',
      name: 'Sustitución temporal'
    }],
    JOB_TYPES_EL_TAIER: [{
      key: 'NUEVA',
      name: 'Nueva'
    }, {
      key: 'REEMPLAZO',
      name: 'Reemplazo'
    }, {
      key: 'TEMPORAL',
      name: 'Temporal'
    }, {
      key: 'FREELANCE',
      name: 'Freelance'
    }],
    JOB_TYPES_SERVINOVA: [{
      key: 'NUEVA',
      name: 'Nueva plaza'
    }, {
      key: 'REEMPLAZO_INTERNO',
      name: 'Reemplazo por movimiento interno'
    }, {
      key: 'REEMPLAZO_ROTACION',
      name: 'Reemplazo por rotación'
    }, {
      key: 'TEMPORAL',
      name: 'Temporal'
    }, {
      key: 'FREELANCE',
      name: 'Freelance'
    }, {
      key: 'CRECIMIENTO',
      name: 'Crecimiento'
    }, {
      key: 'REPOSICION_GARANTIA',
      name: 'Reposición por garantía'
    }],
    REQUERIMIENTO_NIVEL_HABILIDAD: [{
      key: 'JUNIOR',
      name: 'Junior'
    }, {
      key: 'MIDDLE',
      name: 'Middle'
    }, {
      key: 'SENIOR',
      name: 'Senior'
    }],
    REQUERIMIENTO_NIVEL_HABILIDAD_JUNIOR: 'JUNIOR',
    REQUERIMIENTO_NIVEL_HABILIDAD_MIDDLE: 'MIDDLE',
    REQUERIMIENTO_NIVEL_HABILIDAD_SENIOR: 'SENIOR',
    REQUERIMIENTO_MONEDA_RANGO_SALARIAL: [{
      key: 'LOCAL',
      name: 'Moneda local'
    }, {
      key: 'EXTRANJERA',
      name: 'Moneda extranjera'
    }],
    REQUERIMIENTO_MONEDA_RANGO_SALARIAL_LOCAL: 'LOCAL',
    REQUERIMIENTO_MONEDA_RANGO_SALARIAL_EXTRANJERA: 'EXTRANJERA',
    HIRING_TYPES: [{
      key: 'FACTURACION',
      name: 'Facturación interna'
    }, {
      key: 'FACTURA_ESPECIAL',
      name: 'Factura especial interna'
    }, {
      key: 'FACTURACION_EXTERNA',
      name: 'Factura externa'
    }, {
      key: 'PLANILLA',
      name: 'Planilla'
    }],
    HIRING_TYPES_EL_TAIER: [{
      key: 'PLANILLA',
      name: 'Planilla'
    }, {
      key: 'FACTURACION_INTERNA_12_MESES',
      name: 'Facturación interna 12 meses'
    }, {
      key: 'FACTURACION_INTERNA_14_MESES',
      name: 'Facturación interna 14 meses'
    }, {
      key: 'FACTURACION_EXTERNA',
      name: 'Factura externa'
    }],
    HIRING_TYPES_SERVINOVA_INTERNO: [{
      key: 'PLANILLA',
      name: 'Planilla'
    }, {
      key: 'FACTURACION',
      name: 'Facturación'
    }, {
      key: 'PLANILLA_FACTURACION',
      name: 'Ambas'
    }],
    HIRING_TYPES_SERVINOVA_EXTERNO: [{
      key: 'PLANILLA',
      name: 'Planilla'
    }],
    APPLICATION_STATUS: [{
      key: 'PRE_SCREENING',
      name: 'Pre screening'
    }, {
      key: 'RECHAZADO',
      name: 'Rechazado'
    }, {
      key: 'PRUEBAS',
      name: 'Pruebas'
    }, {
      key: 'PRUEBAS_TECNICAS',
      name: 'Pruebas técnicas'
    }, {
      key: 'INVESTIGACION',
      name: 'Investigación'
    }, {
      key: 'EN_ENTREVISTA',
      name: 'En entrevista'
    }, {
      key: 'PRESENTACION',
      name: 'Presentación'
    }, {
      key: 'RECHAZADO_EN_PRUEBAS',
      name: 'Rechazado en pruebas'
    }, {
      key: 'RECHAZADO_INVESTIGACION',
      name: 'Rechazado en investigación'
    }, {
      key: 'RECHAZADO_PRESENTACION',
      name: 'Rechazado en presentación'
    }, {
      key: 'RECHAZADO_PRUEBAS_TECNICAS',
      name: 'Rechazado en pruebas técnicas'
    }, {
      key: 'RECHAZADO_EN_ENTREVISTA',
      name: 'Rechazado en entrevista'
    }, {
      key: 'EN_TERNA',
      name: 'En terna'
    }, {
      key: 'CONFIRMADO_PENDIENTE_DE_INGRESO',
      name: 'Confirmado pendiente de ingreso'
    }, {
      key: 'CONTRATADO',
      name: 'Contratado'
    }, {
      key: 'COMPLETADO',
      name: 'Completado'
    }, {
      key: 'ENVIADO_REQUERIMIENTO',
      name: 'Enviado a otro requerimiento'
    }, {
      key: 'DESISTIO',
      name: 'Desistió'
    }, {
      key: 'DESISTIO_EN_PRUEBAS',
      name: 'Desistió en pruebas'
    }, {
      key: 'DESISTIO_PRESENTACION',
      name: 'Desistió en presentación'
    }, {
      key: 'DESISTIO_PRUEBAS_TECNICAS',
      name: 'Desistió en pruebas técnicas'
    }, {
      key: 'DESISTIO_EN_ENTREVISTA',
      name: 'Desistió en entrevista'
    }, {
      key: 'DESISTIO_INVESTIGACION',
      name: 'Desistió en investigación'
    }],
    APPLICATION_STATUS_PRE_SCREENING: 'PRE_SCREENING',
    APPLICATION_STATUS_RECHAZADO: 'RECHAZADO',
    APPLICATION_STATUS_PRUEBAS: 'PRUEBAS',
    APPLICATION_STATUS_RECHAZADO_EN_PRUEBAS: 'RECHAZADO_EN_PRUEBAS',
    APPLICATION_STATUS_PRESENTACION: 'PRESENTACION',
    APPLICATION_STATUS_RECHAZADO_PRESENTACION: 'RECHAZADO_PRESENTACION',
    APPLICATION_STATUS_PRUEBAS_TECNICAS: 'PRUEBAS_TECNICAS',
    APPLICATION_STATUS_INVESTIGACION: 'INVESTIGACION',
    APPLICATION_STATUS_RECHAZADO_INVESTIGACION: 'RECHAZADO_INVESTIGACION',
    APPLICATION_STATUS_RECHAZADO_PRUEBAS_TECNICAS: 'RECHAZADO_PRUEBAS_TECNICAS',
    APPLICATION_STATUS_EN_ENTREVISTA: 'EN_ENTREVISTA',
    APPLICATION_STATUS_RECHAZADO_EN_ENTREVISTA: 'RECHAZADO_EN_ENTREVISTA',
    APPLICATION_STATUS_EN_TERNA: 'EN_TERNA',
    APPLICATION_STATUS_CONFIRMADO_PENDIENTE: 'CONFIRMADO_PENDIENTE_DE_INGRESO',
    APPLICATION_STATUS_CONTRATADO: 'CONTRATADO',
    APPLICATION_STATUS_DESISTIO: 'DESISTIO',
    APPLICATION_STATUS_DESISTIO_EN_PRUEBAS: 'DESISTIO_EN_PRUEBAS',
    APPLICATION_STATUS_DESISTIO_PRESENTACION: 'DESISTIO_PRESENTACION',
    APPLICATION_STATUS_DESISTIO_PRUEBAS_TECNICAS: 'DESISTIO_PRUEBAS_TECNICAS',
    APPLICATION_STATUS_DESISTIO_INVESTIGACION: 'DESISTIO_INVESTIGACION',
    APPLICATION_STATUS_DESISTIO_EN_ENTREVISTA: 'DESISTIO_EN_ENTREVISTA',
    // Una aplicacion adquiere este estado cuando se acepta en ingreso y el servicio es de reclutamiento
    // y selección
    APPLICATION_STATUS_COMPLETADO: 'COMPLETADO',
    // Una aplicación adquiere este estado cuando es "replicada" hacia un nuevo requerimiento
    APPLICATION_STATUS_ENVIADO_REQUERIMIENTO: 'ENVIADO_REQUERIMIENTO',
    REQUIREMENT_APPLICATION_STATUS: [{
      key: 'PRE_SCREENING',
      name: 'Pre screening'
    }, {
      key: 'RECHAZADO',
      name: 'Rechazado'
    }, {
      key: 'BLACKLIST',
      name: 'Blacklist'
    }],
    PERIODICITY: [{
      key: 'UNICA',
      name: 'Única'
    }, {
      key: 'MENSUAL',
      name: 'Mensual'
    }, {
      key: 'SIN_FACTURACION',
      name: 'Sin facturación'
    }],
    PERIODICITY_UNICA: 'UNICA',
    PERIODICITY_MENSUAL: 'MENSUAL',
    PERIODICITY_SIN_FACTURACION: 'SIN_FACTURACION',
    BILLING_STATUS: [{
      key: 'PENDIENTE_DE_EMITIR',
      name: 'Pendiente de emitir'
    }, {
      key: 'AUTORIZADA',
      name: 'Autorizada'
    }, {
      key: 'EN FACTURACION',
      name: 'En facturación'
    }, {
      key: 'EMITIDA',
      name: 'Emitida'
    }, {
      key: 'ANULADA',
      name: 'Anulada'
    }],
    BILLING_STATUS_PENDIENTE_DE_EMITIR: 'PENDIENTE_DE_EMITIR',
    BILLING_STATUS_AUTORIZADA: 'AUTORIZADA',
    BILLING_STATUS_EN_FACTURACION: 'EN FACTURACION',
    BILLING_STATUS_EMITIDA: 'EMITIDA',
    BILLING_STATUS_ANULADA: 'ANULADA',
    INTERVIEW_STATUS: [{
      key: 'PENDIENTE',
      name: 'Pendiente'
    }, {
      key: 'REALIZADA',
      name: 'Realizada'
    }],
    INTERVIEW_STATUS_PENDIENTE: 'PENDIENTE',
    INTERVIEW_TYPE: [{
      key: 'PRESENCIAL',
      name: 'Presencial'
    }, {
      key: 'TELEFONICA/VIRTUAL',
      name: 'Telefonica/Virtual'
    }],
    INTERVIEW_TYPE_PRESENCIAL: 'PRESENCIAL',
    REFERENCE_TYPE: [{
      key: 'CLIENTE',
      name: 'Cliente'
    }, {
      key: 'PROVEEDOR',
      name: 'Proveedor'
    }],
    REFERENCE_STATUS: [{
      key: 'APROBADA',
      name: 'Aprobada'
    }, {
      key: 'RECHAZADA',
      name: 'Rechazada'
    }],
    REPORTS_JOB_TYPES: [{
      key: 'PLAZAS_SOLICITADAS',
      name: 'Plazas solicitadas'
    }, {
      key: 'PLAZAS_SOLICITADAS_REGION',
      name: 'Plazas solicitadas por región'
    }, {
      key: 'PLAZAS_CONTRATADAS',
      name: 'Plazas contratadas'
    }, {
      key: 'PLAZAS_CONTRATADAS_FUENTE_RECLUTAMIENTO',
      name: 'Plazas contratadas por fuente de reclutamiento'
    }],
    REPORTS_JOB_TYPES_SOLICITADAS: 'PLAZAS_SOLICITADAS',
    REPORTS_JOB_TYPES_SOLICITADAS_REGION: 'PLAZAS_SOLICITADAS_REGION',
    REPORTS_JOB_TYPES_CONTRATADAS: 'PLAZAS_CONTRATADAS',
    REPORTS_JOB_TYPES_CONTRATADAS_FUENTE: 'PLAZAS_CONTRATADAS_FUENTE_RECLUTAMIENTO',
    REPORTS_CANDIDATES_TYPES: [{
      key: 'CANDIDATO_ESPECIFICO',
      name: 'Candidato especifico'
    }, {
      key: 'CANDIDATO_LISTA_NEGRA',
      name: 'Candidato en lista negra'
    }, {
      key: 'CANDIDATOS_POR_PROYECTO',
      name: 'Candidatos por proyecto'
    }, {
      key: 'CANTIDAD_CANDIDATOS_POR_PROYECTO',
      name: 'Cantidad de candidatos por proyecto'
    }],
    REPORTS_SPECIFIC_CANDIDATE: 'CANDIDATO_ESPECIFICO',
    REPORTS_BLACK_LIST_CANDIDATE: 'CANDIDATO_LISTA_NEGRA',
    REPORTS_CANDIDATES_PER_PROJECT: 'CANDIDATOS_POR_PROYECTO',
    REPORTS_NUMBER_CANDIDATES_PER_PROJECT: 'CANTIDAD_CANDIDATOS_POR_PROYECTO',
    REQUIREMENT_SHIFT: [{
      key: 'DIURNA',
      name: 'Diurna'
    }, {
      key: 'MIXTA',
      name: 'Mixta'
    }, {
      key: 'NOCTURNA',
      name: 'Nocturna'
    }, {
      key: 'POR_HORAS',
      name: 'Por horas'
    }],
    REQUIREMENT_SHIFT_ELTAIER: [{
      key: 'DIURNA',
      name: 'Diurna'
    }, {
      key: 'POR_HORAS',
      name: 'Por horas'
    }, {
      key: 'POR_PROYECTO',
      name: 'Por proyecto'
    }],
    WEEK_DAYS: [{
      key: 'LUNES_VIERNES',
      name: 'Lunes - Viernes'
    }, {
      key: 'SABADO',
      name: 'Sábado'
    }, {
      key: 'DOMINGO',
      name: 'Domingo'
    }],
    GENDER: [{
      key: 'MASCULINO',
      name: 'Masculino'
    }, {
      key: 'FEMENINO',
      name: 'Femenino'
    }, {
      key: 'INDEFINIDO',
      name: 'Indefinido'
    }],
    MARITIAL_STATUS: [{
      key: 'CASADO',
      name: 'Casado'
    }, {
      key: 'SOLTERO',
      name: 'Soltero'
    }, {
      key: 'INDIFERENTE',
      name: 'Indiferente'
    }],
    LICENCE_TYPE: [{
      key: 'TIPO_A',
      name: 'Tipo A'
    }, {
      key: 'TIPO_B',
      name: 'Tipo B'
    }, {
      key: 'TIPO_C',
      name: 'Tipo C'
    }, {
      key: 'TIPO_E',
      name: 'Tipo E'
    }, {
      key: 'TIPO_M',
      name: 'Tipo M'
    }],
    REPORT_APLICACIONES: [{
      key: 'REPORTE_GENERAL',
      name: 'General'
    }, {
      key: 'REPORTE_RESUMEN',
      name: 'Resumen'
    }],
    REPORT_APLICACIONES_GENERAL: 'REPORTE_GENERAL',
    REPORT_APLICACIONES_RESUMEN: 'REPORTE_RESUMEN',
    REPORT_REQUERIMIENTO: [{
      key: 'REPORTE_GENERAL',
      name: 'General'
    }, {
      key: 'REPORTE_CONTRATADOS',
      name: 'Contratados'
    }],
    REPORT_REQUERIMIENTO_GENERAL: 'REPORTE_GENERAL',
    REPORT_REQUERIMIENTO_CONTRATADOS: 'REPORTE_CONTRATADOS',
    CLIENT_CONTACT_TYPE: [{
      key: 'FACTURACION',
      name: 'Facturación'
    }, {
      key: 'SOLICITUD',
      name: 'Solicitud'
    }],
    MONTHS_BY_INDEX: [{
      key: 0,
      name: 'Enero'
    }, {
      key: 1,
      name: 'Febrero'
    }, {
      key: 2,
      name: 'Marzo'
    }, {
      key: 3,
      name: 'Abril'
    }, {
      key: 4,
      name: 'Mayo'
    }, {
      key: 5,
      name: 'Junio'
    }, {
      key: 6,
      name: 'Julio'
    }, {
      key: 7,
      name: 'Agosto'
    }, {
      key: 8,
      name: 'Septiembre'
    }, {
      key: 9,
      name: 'Octubre'
    }, {
      key: 10,
      name: 'Noviembre'
    }, {
      key: 11,
      name: 'Diciembre'
    }],
    NOTIFICATION_CONFIG_EVENTS: [{
      key: '',
      name: 'Seleccionar'
    }, {
      key: 'ENTITY_CREATED',
      name: 'Entidad creada'
    }, {
      key: 'ENTITY_UPDATED',
      name: 'Entidad actualizada'
    }, {
      key: 'ENTITY_PROPERTY_UPDATED',
      name: 'Propiedad actualizada'
    }, {
      key: 'CRON',
      name: 'Tarea recursiva'
    }, {
      key: 'ONLY_TEMPLATE',
      name: 'Solo plantilla'
    }],
    ACADEMIC_FORMATION: [{
      key: 'SECUNDAROA',
      name: 'Secundaria'
    }, {
      key: 'TECNICA_BASICA',
      name: 'Técnica básica (1 ó 2 años)'
    }, {
      key: 'TECNICA_SUPERIOR',
      name: 'Técnica superior (3 ó 4 años)'
    }, {
      key: 'UNIVERSITARIO',
      name: 'Universitario'
    }],
    ACADEMIC_FORMATION_SERVINOVA: [{
      key: 'SECUNDAROA',
      name: 'Secundaria'
    }, {
      key: 'TECNICA_BASICA',
      name: 'Técnica básica'
    }, {
      key: 'TECNICA_SUPERIOR',
      name: 'Técnica superior'
    }, {
      key: 'UNIVERSITARIO',
      name: 'Universitario'
    }],
    ACADEMIC_DEGREE: [{
      key: 'BACHILLER',
      name: 'Bachiller'
    }, {
      key: 'TITULO',
      name: 'Título/Licenciatura'
    }],
    ACADEMIC_DEGREE_SERVINOVA: [{
      key: 'DIVERSIFICADO',
      name: 'Diversificado'
    }, {
      key: 'UNIVERSITARIO',
      name: 'Universitario'
    }],
    NIVELES_FORMACION_ACADEMICA: [{
      key: 'PRIMARIO',
      name: 'Primario'
    }, {
      key: 'BASICO',
      name: 'Básico'
    }, {
      key: 'MEDIO',
      name: 'Medio'
    }, {
      key: 'SUPERIOR',
      name: 'Superior'
    }],
    MASTERS_ESTATUS: [{
      key: 'PROGRESO',
      name: 'En progreso'
    }, {
      key: 'GRADUADO',
      name: 'Culminado'
    }, {
      key: 'INCOMPLETO',
      name: 'Pausado'
    }],
    OTROS_CURSOS: [{
      key: 'CURSOS',
      name: 'Cursos'
    }, {
      key: 'DIPLOMADOS',
      name: 'Diplomados'
    }],
    WORKING_HOURS: [{
      key: 'SIETE_A_CINCO',
      name: '7:00-5:00'
    }, {
      key: 'OCHO_A_SEIS',
      name: '8:00-6:00'
    }, {
      key: 'NUEVA_A_SIETE',
      name: '9:00-7:00'
    }],
    MASTERY_LEVEL: [{
      key: 'BASICO',
      name: 'Básico'
    }, {
      key: 'MEDIO',
      name: 'Medio'
    }, {
      key: 'AVANZADO',
      name: 'Avanzado'
    }],
    COMPETENCE_TYPE: [{
      key: 'TECNICA',
      name: 'Técnica'
    }, {
      key: 'LIDERAZGO',
      name: 'Liderazgo'
    }],
    COMPETENCE_TYPE_TECNICA: 'TECNICA',
    COMPETENCE_TYPE_LIDERAZGO: 'LIDERAZGO',
    FORMA_TRABAJO: [{
      key: 'PRESENCIAL',
      name: 'Presencial'
    }, {
      key: 'REMOTO',
      name: 'Remoto (Home Office)'
    }, {
      key: 'HIBRIDO',
      name: 'Hibrido'
    }],
    BENEFICIOS_EXTRA: [{
      key: 'CELULAR_PLAN_CORPORATIVO',
      name: 'Celular con plan corporativo'
    }, {
      key: 'COMPUTADORA_PORTATIL',
      name: 'Computadora portátil'
    }, {
      key: 'VEHICULO',
      name: 'Vehiculo'
    }, {
      key: 'SEGURO_MEDICO_INDIVIDUAL',
      name: 'Seguro médico individual'
    }, {
      key: 'SEGURO_MEDICO_FAMILIAR',
      name: 'Seguro médico familiar'
    }, {
      key: 'SEGURO_VIDA',
      name: 'Seguro de vida'
    }, {
      key: 'PARQUEO_SIN_COSTO',
      name: 'Paqueo sin costo'
    }, {
      key: 'VIVIENDA',
      name: 'Vivienda'
    }, {
      key: 'BONOS_ADICIONALES',
      name: 'Bonos adicionales'
    }, {
      key: 'NINGUNO',
      name: 'Ninguno'
    }, {
      key: 'OTROS',
      name: 'Otros'
    }],
    GENDERS_TYPE: [{
      key: 'MASCULINO',
      name: 'Masculino'
    }, {
      key: 'FEMENINO',
      name: 'Femenino'
    }],
    GRADO_ACADEMICO_SERVINOVA: [{
      key: 'Analfabeta',
      name: 'Analfabeta'
    }, {
      key: 'Primaria',
      name: 'Primaria'
    }, {
      key: 'Nivel Básico',
      name: 'Nivel Básico'
    }, {
      key: 'Diversificado',
      name: 'Diversificado'
    }, {
      key: 'Estudios Técnicos Universitarios',
      name: 'Estudios Técnicos Universitarios'
    }, {
      key: 'Licenciatura',
      name: 'Licenciatura'
    }, {
      key: 'Maestría',
      name: 'Maestría'
    }, {
      key: 'Postgrado',
      name: 'Postgrado'
    }, {
      key: 'Doctorado',
      name: 'Doctorado'
    }],
    GENDERS_TYPE_MASCULINO: 'MASCULINO',
    GENDERS_TYPE_FEMENINO: 'FEMENINO',
    CODIGO_MONEDA_QUETZAL: 'GTQ',
    CODIGO_MONEDA_DOLAR: 'USD',
    VALOR_COMPENSACION_BONIFICACION: 250,
    VALOR_COMPENSACION_CARGA: 1.1666,
    SERVINOVA_FUENTE_RECLUTAMIENTO_REFERIDO_CODE: 'FR-0010',
    SERVINOVA_TIPOS_FEE: [{
      key: 'CERRADO',
      name: 'Fee cerrado'
    }, {
      key: 'POR_TRABAJO',
      name: 'Fee por trabajo'
    }, {
      key: 'POR_HORA',
      name: 'Fee por hora'
    }],
    DEFAULT_PHASES: [{
      key: 'INICIAL',
      name: 'Inicial'
    }, {
      key: 'APLICACIONES',
      name: 'Aplicaciones'
    }, {
      key: 'ENTREVISTAS',
      name: 'Entrevistas'
    }, {
      key: 'PRUEBAS_PSICOMETRICAS',
      name: 'Pruebas psicométricas'
    }, {
      key: 'PRUEBAS_TECNICAS',
      name: 'Pruebas técnicas'
    }, {
      key: 'INVESTIGACION',
      name: 'Investigación'
    }, {
      key: 'TERNA',
      name: 'En terna'
    }, {
      key: 'INGRESO',
      name: 'Ingreso'
    }]
  };
  _exports.default = _default;
});
define("ods-app/controllers/aplicacion/record/aspectos-evaluar", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/aplicacion-aspecto-evaluar"], function (_exports, _abstractModuleIndex, _aplicacionAspectoEvaluar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    editRoute: 'aplicacion.record.aspectos-evaluar.record',
    modelClass: _aplicacionAspectoEvaluar.default,
    reload: true,
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'aplicacion'
        },
        value: this.get('record.id') || -1
      }];
    }),
    columns: Ember.computed(function () {
      let columns = Ember.A([{
        label: 'Sección',
        valuePath: 'seccionInforme.nombre',
        sortable: false,
        width: '100px'
      }, {
        label: 'Observaciones',
        valuePath: 'observaciones',
        sortable: false,
        width: '100px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
      return columns;
    }),

    reloadModel() {
      this.set('reload', true);
    }

  });

  _exports.default = _default;
});
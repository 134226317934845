define("ods-app/mixins/upload-helper", ["exports", "ember-uploader/uploaders/uploader", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators", "ember-changeset/utils/is-changeset", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _uploader, _emberChangeset, _emberChangesetValidations, _validators, _isChangeset, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_fileSaver.default, {
    toast: Ember.inject.service(),
    session: Ember.inject.service(),
    config: Ember.inject.service(),
    loader: Ember.inject.service(),
    ajaxService: Ember.inject.service(),
    uploadModelName: null,
    uploadRelatedEntity: null,
    uploadRelatedEntityAttr: null,
    skipNewFileChangeset: false,
    fileChangeset: null,
    isUploading: false,
    uploadUrl: Ember.computed('config.API.{host,namespace}', function () {
      return `${this.get('config.API.host')}/upload`;
    }),
    fileValidations: {
      file: (0, _validators.validatePresence)({
        presence: true
      })
    },

    _getNewUploader() {
      let {
        token
      } = this.get('session.data.authenticated');

      let uploader = _uploader.default.create({
        url: this.uploadUrl,
        paramName: 'archivo',
        ajaxSettings: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });

      return uploader;
    },

    _setNuevoArchivo() {
      if (this.skipNewFileChangeset) {
        return;
      }

      let fileChangeset = new _emberChangeset.default(Object.create({
        file: null
      }), (0, _emberChangesetValidations.default)(this.get('fileValidations')), this.get('fileValidations'));
      this.set('fileChangeset', fileChangeset);
    },

    uploadCurrentFile() {
      if (!(0, _isChangeset.default)(this.fileChangeset)) {
        return Ember.RSVP.resolve(null);
      }

      if (!this.fileChangeset.get('file')) {
        return Ember.RSVP.resolve(null);
      }

      return this.fileChangeset.validate().then(() => {
        if (!this.fileChangeset.get('isValid')) {
          this.toast.error('Problemas con el archivo! No se puede cargar.');
          return;
        }

        let uploader = this._getNewUploader();

        let uploadModel = null;

        if (this.uploadModelName) {
          uploadModel = this.store.createRecord(this.uploadModelName, {
            uploader: uploader
          });
        } // Just to disable actions while a file is being uploaded.


        this.fileChangeset.set('uploader', uploader);

        if (uploadModel && this.uploadRelatedEntity && this.uploadRelatedEntityAttr) {
          uploadModel.set(this.uploadRelatedEntityAttr, this.uploadRelatedEntity);
        }

        const successHandler = response => {
          this.toast.success('Documento cargado exitosamente!');

          this._setNuevoArchivo();

          return Ember.RSVP.resolve(response);
        };

        this.set('isUploading', true);
        this.loader.setIsLoading();
        return uploader.upload(this.fileChangeset.get('file')).then(uploadData => {
          let uploadRecord = this.store.createRecord('upload', uploadData);
          return uploadRecord.reload();
        }).then(uploadRecord => {
          if (!uploadModel) {
            return successHandler(uploadRecord);
          }

          uploadModel.set('upload', uploadRecord);
          return uploadModel.save().then(successHandler.bind(this));
        }).catch(error => {
          this.toast.error('No se pudo cargar correctamente el documento.');

          if (error.responseJSON && error.responseJSON.errors) {
            Object.keys(error.responseJSON.errors).forEach(key => {
              let keyErrors = error.responseJSON.errors[key];
              let errorMsg = `Error con la propiedad ${key}.`;

              if (Array.isArray(keyErrors)) {
                keyErrors.forEach(e => this.toast.error(e.message || e.title || e.description || errorMsg));
              } else {
                this.toast.error(errorMsg);
              }
            });
          }

          if (uploadModel) {
            // TODO: Encontrar la forma de eliminar en el server el archivo si ya se
            // cargó. Este destroy no lo elimina en el server porque el modelo del
            // upload todavía no se ha persistido en el backend.
            if (uploadModel.get('upload')) {
              uploadModel.get('upload').destroyRecord();
            }

            uploadModel.destroyRecord();
          }

          throw error;
        }).finally(() => {
          // Because in each load a new one is instantiated.
          uploader.destroy();
          this.set('isUploading', false);
          this.loader.setNotLoading();
        });
      });
    },

    saveAttachmentFile(content, xhrRequest) {
      let disposition = xhrRequest.getResponseHeader('Content-Disposition');

      if (!disposition) {
        throw new Error('Got no Content-Disposition header');
      }

      let contentType = xhrRequest.getResponseHeader('Content-Type');

      if (!contentType) {
        throw new Error('Got no Content-Type header');
      }

      let fileName = 'document';

      if (disposition && disposition.indexOf('attachment') !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(disposition);

        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }
      }

      this.saveFileAs(fileName, content, contentType);
    },

    downloadFile(fileObject) {
      if (!fileObject.get('upload.id')) {
        this.toast.error('Archivo inválido. No se puede descargar.');
        return;
      }

      let fileId = fileObject.get('upload.id');
      this.loader.setIsLoading();
      let downloadPromise = this.ajaxService.request(`/uploads/${fileId}/download`, {
        dataType: 'arraybuffer'
      }); // TODO: Open the file in a new tab instead of downloading it directly.

      return downloadPromise.then(response => this.saveAttachmentFile(response, downloadPromise.xhr)).finally(() => this.loader.setNotLoading());
    },

    actions: {
      deleteFile(fileObject) {
        this.send('openModal', 'popups/simple-confirm', {
          message: '¿Está seguro de eliminar el archivo?',
          onSubmit: () => {
            this.loader.setIsLoading();
            fileObject.destroyRecord().then(() => {
              this.toast.success('Archivo eliminado exitosamente!');
            }).catch(error => {
              this.toast.error('No se pudo eliminar el archivo.');

              if (error.errors && Array.isArray(error.errors)) {
                error.errors.forEach(e => this.toast.error(e.message));
              }
            }).finally(() => {
              this.loader.setNotLoading();
            });
          }
        });
      },

      uploadCurrentFile() {
        return this.uploadCurrentFile();
      }

    }
  });

  _exports.default = _default;
});